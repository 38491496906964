import React from 'react';
import { Card, CardBody, Container, Table } from 'reactstrap';
import Pulse from 'react-reveal/Pulse';
let data = [
	{
		feature: 'APY',
		payc: '385,945,80%',
		eversafue: '385,945,80%',
		titano: '102,483.58%',
	},
	{
		feature: 'Fee',
		payc: '11% / 18%	',
		eversafue: '14% / 16%',
		titano: '13% / 18%',
	},
	{
		feature: 'BUSD reflection',
		payc: '9%',
		eversafue: '7%',
		titano: '❌',
	},
	{
		feature: 'Interest period',
		payc: '365 days',
		eversafue: '365 days',
		titano: '365 days',
	},
	{
		feature: 'Rebase',
		payc: '15 mins',
		eversafue: '15 mins',
		titano: '30 mins',
	},
	{
		feature: 'Casino / Lottery',
		payc: 'soon',
		eversafue: '❌',
		titano: '✅',
	},
	{
		feature: 'NFT',
		payc: '✅',
		eversafue: '❌',
		titano: '❌',
	},
	{
		feature: 'Automatic Burn',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Insurance Fund',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Auto-Liquidity',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Auto-Staking',
		payc: '✅',
		eversafue: '✅',
		titano: '✅',
	},
	{
		feature: 'Fees Hard Coded',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Rug-proof: no minting code',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Rug-proof: No manual Adjusting',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Rug-proof: Fixed Rebase Time',
		payc: '✅',
		eversafue: '✅',
		titano: '✅',
	},
	{
		feature: 'Rug-proof: Liquidity Locked',
		payc: '✅',
		eversafue: '✅',
		titano: '✅',
	},
	{
		feature: 'Audit',
		payc: '✅',
		eversafue: '✅',
		titano: '✅',
	},
	{
		feature: 'KYC',
		payc: '✅',
		eversafue: '❌',
		titano: '❌',
	},
	{
		feature: 'Manual Token Buyback',
		payc: '✅',
		eversafue: '✅',
		titano: '❌',
	},
	{
		feature: 'Limited token',
		payc: '13,5 years',
		eversafue: '13,5 years',
		titano: 'No limited',
	},
];
function CompetitiveAdvantages() {
	return (
		<>
			<Container className='advantage__container text-center ' fluid>
				<Pulse left>
					<h2 className='section__heading'>Competitive Advantages</h2>
				</Pulse>
				<Card className='table__card mt-5'>
					<CardBody>
						<Table responsive borderless>
							<thead>
								<tr>
									<th></th>
									<th className='text-left'>Features</th>
									<th className='text-left'>PAYC Finance</th>
									<th className='text-left'>Eversafu</th>
									<th className='text-left'>Titano</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
								</tr>

								{data?.map((d, idx) => {
									return (
										<tr key={idx}>
											<td></td>
											<td className='first__td text-left'>
												<h2 className='mb-0 second__td'>
													{d.feature}
												</h2>
												{/* <small className='mt-0'>
													WhitePaper
												</small> */}
											</td>
											<td className='simple__td'>
												{d.payc}
											</td>
											<td className='simple__td'>
												{d.eversafue}
											</td>
											<td className='simple__td'>
												{d.titano}
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default CompetitiveAdvantages;
