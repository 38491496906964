import { Col, Container, Row, Nav, NavItem, NavLink } from 'reactstrap';
import DashboardLogo from '../assets/img/dashboard-logo.png';
import HomeActive from '../assets/img/home-active.png';
import RewardActive from '../assets/img/medal-active.png';
import calcActive from '../assets/img/calc-active.png';
import MiniLogo from '../assets/img/mini-logo.png';
import DashboardLogoMobile from '../assets/img/dashboard-logo-mbl.png';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Dashboard = props => {
	const history = useHistory();
	return (
		<>
			<Container fluid className='px-0 overflow-hidden'>
				<Row>
					<Col xl='2' sm='3' xs='2' className='pr-0'>
						<div className='dashboard__sidebar h-100'>
							<Nav vertical className='vertical__nav'>
								<NavLink tag={Link} to="/">
								<img
									src={DashboardLogo}
									alt='logo'
									className='img-fluid dashboard__logo d-none d-sm-block'
								/>
								<img
									src={DashboardLogoMobile}
									alt='logo'
									className=' dashboard__logo d-block d-sm-none'
								/>
								</NavLink>
								<NavItem className='first__nav-item'>
									<NavLink
										tag={Link}
										to='/dashboard'
										className={`${
											history.location.pathname ==
												'/dashboard' && 'active__link'
										}`}
									>
										<div className='d-flex align-items-center'>
											<img
												src={HomeActive}
												alt='home'
												className='mr-2'
											/>
											<p className='mb-0 d-none d-sm-block'>
												Dashboard
											</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										to='/claim-reward'
										className={`${
											history.location.pathname ==
												'/claim-reward' &&
											'active__link'
										}`}
									>
										{' '}
										<div className='d-flex align-items-center'>
											<img
												src={RewardActive}
												alt='reward'
												className='mr-2'
											/>
											<p className='mb-0 d-none d-sm-block'>
												Claim Reward
											</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										to='/PAYC'
										className={`${
											history.location.pathname ==
												'/PAYC' && 'active__link'
										}`}
									>
										<div className='d-flex align-items-center'>
											<img
												src={MiniLogo}
												alt='PAYC'
												className='mr-2'
											/>
											<p className='mb-0 nav__payc d-none d-sm-block'>
												PAYC
											</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										to='/calculator'
										className={`${
											history.location.pathname ==
												'/calculator' && 'active__link'
										}`}
									>
										<div className='d-flex align-items-center'>
											<img
												src={calcActive}
												alt='Estimate'
												className='mr-2'
											/>
											<p className='mb-0 d-none d-sm-block'>
												Calculator
											</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
								<NavLink
										// tag={Link}
										// href='https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x9A5A5493b374AC07CFe148b393926EDddc55061f'
										// target="_blank"
										className={`${
											history.location.pathname ==
												'/calculator' && 'active__link'
										}`}
									>
									<a
										href='https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x9A5A5493b374AC07CFe148b393926EDddc55061f'	
										target="_blank"
										className='nav-item'
									>
										<div className='d-flex align-items-center'>
											<p className='mb-0 d-none d-sm-block'>
												Swap
											</p>
										</div>
											</a>
									</NavLink>
								</NavItem>

								<NavItem>
								<NavLink
										// tag={Link}
										// href='https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x9A5A5493b374AC07CFe148b393926EDddc55061f'
										// target="_blank"
										className={`${
											history.location.pathname ==
												'/calculator' && 'active__link'
										}`}
									>
									<a
										href='https://payc-finance.gitbook.io/docs'	
										target="_blank"
										className='nav-item'
									>
										<div className='d-flex align-items-center'>
											<p className='mb-0 d-none d-sm-block'>
												Docs
											</p>
										</div>
											</a>
									</NavLink>
								</NavItem>
							</Nav>
						</div>
					</Col>
					<Col xl='10' sm='9' xs='10' className=' pl-0'>
						<div className='dashboard__content'>
							{props.children}
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
