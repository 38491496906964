import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import TelegramIcon from '../assets/img/telegram.png';
import Bounce from 'react-reveal/Bounce';
import Pulse from 'react-reveal/Pulse';
import TeamLogo from '../assets/img/tt-logo.jpg';
function Team() {
	return (
		<>
			<Container fluid className='team__container text-center'>
				<Pulse left>
					<h2 className='team__heading mb-5'>Team</h2>
				</Pulse>
				<Row className='team__row justify-content-center'>
					<Col lg='3' md='6' className='mr-0 mr-lg-5'>
						<Bounce left>
							<Card className='team__card text-center'>
								<div className=''>
									<img
										src={TeamLogo}
										alt='team logo'
										className='img-fluid team__img'
									/>
								</div>
								<h2 className='team__name mt-4'>Major</h2>
								<h2 className='team__rank'>Co-Founder</h2>
								<img
									src={TelegramIcon}
									alt='send'
									className='img-fluid social__icon mx-auto'
								/>
							</Card>
						</Bounce>
					</Col>
					<Col lg='3' md='6' className='mt-3 mt-md-0'>
						<Bounce right>
							<Card className='team__card text-center'>
								<div className=''>
									<img
										src={TeamLogo}
										alt='team logo'
										className='img-fluid team__img'
									/>
								</div>
								<h2 className='team__name mt-4'>Sayajin</h2>
								<h2 className='team__rank'>Co-Founder</h2>
								<img
									src={TelegramIcon}
									alt='send'
									className='img-fluid social__icon mx-auto'
								/>
							</Card>
						</Bounce>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Team;
