import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Bounce from 'react-reveal/Bounce';
import Pulse from 'react-reveal/Pulse';
function Tokenomics() {
	return (
		<>
			<Container fluid className='tokenomic__container text-center'>
				<p className='tokenomic__top-line mb-0'>EXCLUSIVE</p>
				<Pulse>
					<h2 className='tokenomic__heading mt-1'>Tokenomics</h2>
				</Pulse>
				<p className='tokenomic__desc mt-4'>
					The amount of the fees (11% for buys and 18% for sells)
					allows Payc Finance to provide{' '}
					<br className='d-none d-sm-block' /> $PAYC Finance holders
					with the stable high yield of 385,945.80% annually.
				</p>
				<div className='d-block d-sm-flex justify-content-center mt-4'>
				<div className='mr-0 mr-sm-4'>
						<div className='circle__wrapper mx-auto'>
							<CircularProgressbar
								value={54}
								text={`${54}%`}
								strokeWidth='10'
								styles={{
									path: {
										// Path color
										stroke: `rgba(224, 0, 255, 1)`,
										strokeLinecap: 'butt',
									},
									// Customize the circle behind the path, i.e. the "total progress"
									trail: {
										// Trail color
										stroke: 'white',
										strokeLinecap: 'butt',
									},
									// Customize the text
									text: {
										fill: '#A850FF',
										fontSize: '20px',
										fontWeight: '800',
									},
								}}
							/>
						</div>
						<p className='progress__line mt-2'> Burnt</p>
					</div>
					<div className='mr-0 mr-sm-4'>
						<div className='circle__wrapper mx-auto'>
							<CircularProgressbar
								value={5}
								text={`${5}%`}
								strokeWidth='10'
								styles={{
									path: {
										// Path color
										stroke: `rgba(224, 0, 255, 1)`,
										strokeLinecap: 'butt',
									},
									// Customize the circle behind the path, i.e. the "total progress"
									trail: {
										// Trail color
										stroke: 'white',
										strokeLinecap: 'butt',
									},
									// Customize the text
									text: {
										fill: '#A850FF',
										fontSize: '20px',
										fontWeight: '800',
									},
								}}
							/>
						</div>
						<p className='progress__line mt-2'> Airdrop</p>
					</div>
					<div className='mr-0 mr-sm-4'>
						<div className='circle__wrapper mx-auto'>
							<CircularProgressbar
								value={15}
								text={`${15}%`}
								strokeWidth='10'
								styles={{
									path: {
										// Path color
										stroke: `rgba(224, 0, 255, 1)`,
										strokeLinecap: 'butt',
									},
									// Customize the circle behind the path, i.e. the "total progress"
									trail: {
										// Trail color
										stroke: 'white',
										strokeLinecap: 'butt',
									},
									// Customize the text
									text: {
										fill: '#A850FF',
										fontSize: '20px',
										fontWeight: '800',
									},
								}}
							/>
						</div>
						<p className='progress__line mt-2'>Development/Team</p>
					</div>
					<div className='mr-0 mr-sm-4'>
						<div className='circle__wrapper mx-auto'>
							<CircularProgressbar
								value={5}
								text={`${5}%`}
								strokeWidth='10'
								styles={{
									path: {
										// Path color
										stroke: `rgba(224, 0, 255, 1)`,
										strokeLinecap: 'butt',
									},
									// Customize the circle behind the path, i.e. the "total progress"
									trail: {
										// Trail color
										stroke: 'white',
										strokeLinecap: 'butt',
									},
									// Customize the text
									text: {
										fill: '#A850FF',
										fontSize: '20px',
										fontWeight: '800',
									},
								}}
							/>
						</div>
						<p className='progress__line mt-2'> Marketing</p>
					</div>
					<div className=''>
						<div className='circle__wrapper mx-auto'>
							<CircularProgressbar
								value={21}
								text={`${21}%`}
								strokeWidth='10'
								styles={{
									path: {
										// Path color
										stroke: `rgba(224, 0, 255, 1)`,
										strokeLinecap: 'butt',
									},
									// Customize the circle behind the path, i.e. the "total progress"
									trail: {
										// Trail color
										stroke: 'white',
										strokeLinecap: 'butt',
									},
									// Customize the text
									text: {
										fill: '#A850FF',
										fontSize: '20px',
										fontWeight: '800',
									},
								}}
							/>
						</div>
						<p className='progress__line mt-2'> Presales</p>
					</div>
				</div>

				<p className='tokenomic__fee-info mt-4'>
					<Bounce left>
						<span>Buy Trading Fees: 11%</span>
					</Bounce>{' '}
					<br /> 2.0% - LP 5.0% - PDF ( BUSD Reflection ) 2.0% -
					Treasury 2.0% - Auto Fire Pit
				</p>
				<p className='tokenomic__fee-info mt-4'>
					<Bounce left>
						<span>Sell Trading Fees: 18%</span>
					</Bounce>{' '}
					<br /> 2.0% - LP 9.0% - PDF ( BUSD Reflection ) 4.5% -
					Treasury 2.5% - Auto Fire Pit
				</p>
			</Container>
			<Container fluid className='px-0'>
				<div className='vertical__stroke1'></div>
				<div className='vertical__stroke'></div>
			</Container>
		</>
	);
}

export default Tokenomics;
