import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Container,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Button,
} from 'reactstrap';
import Logo from '../assets/img/logo.png';
function Header({ homeScroll, tokenomicScroll, faqScroll, teamScroll }) {
	const [collapseOpen, setCollapseOpen] = useState(false);
	const history = useHistory();
	return (
		<>
			<Container className='custom__container' fluid>
				<Navbar expand='md' className='aling-items-center'>
					<NavbarBrand href='/' className='d-flex align-items-center'>
						<img src={Logo} alt='logo' className='mr-2' />
						<p className='mb-0 logo__title'>PAYC Finance</p>
					</NavbarBrand>
					<NavbarToggler
						className='bg__primary'
						onClick={() => setCollapseOpen(!collapseOpen)}
					>
						<i className='fa fa-bars text-white'></i>
					</NavbarToggler>
					<Collapse navbar isOpen={collapseOpen}>
						<Nav
							className='ml-auto custom__nav align-items-center'
							navbar
						>
							<NavItem className='pointer'>
								<NavLink onClick={homeScroll}>Home</NavLink>
							</NavItem>
							<NavItem className='pointer'>
								<NavLink onClick={tokenomicScroll}>
									Tokenomics
								</NavLink>
							</NavItem>
							<NavItem className='pointer'>
								<NavLink onClick={faqScroll}>FAQ</NavLink>
							</NavItem>
							<NavItem className='mr-2 pointer'>
								<NavLink onClick={teamScroll}>Team</NavLink>
							</NavItem>

							<NavItem className='w-100'>
								<Button
									className='open__app-btn w-100'
									onClick={() => history.push('/dashboard')}
								>
									Open App
								</Button>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
			</Container>
		</>
	);
}

export default Header;
