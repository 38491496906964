import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import HeroVideo from '../assets/img/hero-vid.mp4';
import Bounce from 'react-reveal/Bounce';

function MainHeader() {
	return (
		<>
			<Container fluid className='main__header'>
				<Row className='justify-content-between'>
					<Col lg='5'>
						<Bounce left>
							<p className='main__header-top-tagline mb-0'>
								Revolutionary Finance
							</p>
							<p className='main__header-top-tagline mt-0 mb-0'>
								PAYC DeFi
							</p>
						</Bounce>
						<h2 className='payc__info-heading text-left mt-3'>
							Dual Reward with Auto-Staking, Auto-Compounding &
							BUSD Reflection
						</h2>
						<Bounce right>
							<p className='main__header-subtitle mb-0'>
								Up to 385'945.80% APY + 9% BUSD
							</p>
						</Bounce>
						<div className='d-flex mt-4 align-items-center'>
							<Button className='btn__buy mr-4'>
								Buy $PAYC{' '}
							</Button>
							<a
								href='https://payc-finance.gitbook.io/docs'
								target='_blank'
							>
								<h2 className='about__payc-btn'>
									About $PAYC{' '}
									<i className='fas fa-chevron-right ml-2'></i>{' '}
								</h2>
							</a>
							<a target="_blank" href="https://www.pinksale.finance/launchpad/0x2e617d8B1574beC6F840e22A93bE78d8792D5493?chain=BSC" className='btn__buy mr-4 presale_url'>
								Presale
							</a>
						</div>
					</Col>
					<Col lg='6' className='align-self-center mt-5 mt-lg-0'>
						<div className='hero__video'>
							<video
								src={HeroVideo}
								autoPlay
								loop
								playsInline
								muted
								className='img-fluid'
							></video>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default MainHeader;
