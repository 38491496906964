import React from 'react';
import { Container } from 'reactstrap';
import DropboxIcon from '../assets/img/dropbox.png';
import Telegram from '../assets/img/telegram.png';
import InstaIcon from '../assets/img/Instagram.png';
import TwitterIcon from '../assets/img/Twitter.png';
import GithubIcon from '../assets/img/github.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
function Footer() {
	return (
		<>
			<div className='footer'>
				<Container fluid className='footer__container'>
					<div className='d-flex align-items-center'>
						<h2 className='mb-0'>PAYC Finance</h2>
						<div className='ml-auto'>
							<a
								target='_blank'
								href='https://linktr.ee/paycfinance'
							>
								<img
									src={DropboxIcon}
									alt='Dropbox'
									className='img-fluid mr-3'
								/>
							</a>
							<a
								target='_blank'
								href='https://github.com/paycfinance/autostaking'
							>
								<img
									src={GithubIcon}
									alt='Github'
									className='img-fluid mr-3'
								/>
							</a>
							<a target='_blank' href='https://t.co/gzFRoAfhxa'>
								<img
									src={Telegram}
									alt='Telegram'
									className='img-fluid mr-3'
								/>
							</a>
							<a
								target='_blank'
								href='https://www.instagram.com/payc_finance'
							>
								<img
									src={InstaIcon}
									alt='Instagram'
									className='img-fluid mr-3'
								/>
							</a>
							<a
								target='_blank'
								href='https://twitter.com/FinancePayc'
							>
								<img
									src={TwitterIcon}
									alt='Twitter'
									className='img-fluid mr-3'
								/>
							</a>
						</div>
					</div>

					<small className='text-white '>
						Copyright PAYC Finance © {moment().year()}
					</small>
				</Container>
			</div>
		</>
	);
}

export default Footer;
