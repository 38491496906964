import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import DashboardHeader from '../components/DashboardHeader';

function PAYC() {
	return (
		<>
			<Container className='pt-5 pb-5'>
				<DashboardHeader title='PAYC Dashboard' />
				<Row className='mt-5 mx-1 claim__row'>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Market Cap</p>
						<p className='reward__price mb-0'>$ 0</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Circulating Supply</p>
						<p className='reward__price mb-0'>0</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>
							Total Dividend Distribute
						</p>
						<p className='reward__price mb-0'>0 PAYC</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>PAYCF Price</p>
						<p className='reward__price mb-0'>0 </p>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col lg='4'>
						<Row>
							<Col xs='12' className='mt-3'>
								<div className='inactive__card'>
									<p className='mb-0 card__title'>
										PAYC Price
									</p>
									<p className='mb-0 card__price'>$2.0158</p>
								</div>
							</Col>
							<Col xs='12' className='mt-3'>
								<div className='inactive__card'>
									<p className='mb-0 card__title'>
										PAYC Price
									</p>
									<p className='mb-0 card__price'>$2.0158</p>
								</div>
							</Col>
							<Col xs='12' className='mt-3'>
								<div className='inactive__card'>
									<p className='mb-0 card__title'>
										PAYC Price
									</p>
									<p className='mb-0 card__price'>$2.0158</p>
								</div>
							</Col>
						</Row>
					</Col>
					<Col lg='4' className='mt-3'>
						<div className='dao__coming h-100'>
							<p className='dao__tagline'>DAO Vote</p>
							<h2 className='dao__soon mt-4 mb-5'>
								Coming Soon!
							</h2>
							<strong>Vote Project</strong>
							<br />
							<small>Vote your favourite project</small>
							<br />
							<Button block className='dao__btn mt-5'>
								Available Soon!
							</Button>
						</div>
					</Col>
					<Col lg='4' className='mt-3'>
						<div className='game__coming h-100'>
							<p className='game__tagline'>G.A.M.E. Protocol</p>
							<h2 className='game__soon mt-4 mb-5'>
								Coming Soon!
							</h2>
							<strong>Win by Saving</strong>
							<br />
							<small>
								Save money and have a chance to win every week.
							</small>
							<br />
							<Button block className='game__btn mt-4'>
								Available Soon!
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default PAYC;
