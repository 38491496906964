import Dashboard from '../views';
import Calculator from '../views/Calculator';
import ClaimReward from '../views/ClaimReward';
import MainView from '../views/MainView';
import PYAC from '../views/PYAC';

let routes = [
	{
		path: '/',
		component: MainView,
		layout: 'main',
	},
	{
		path: '/dashboard',
		component: Dashboard,
		layout: 'dashboard',
	},
	{
		path: '/claim-reward',
		component: ClaimReward,
		layout: 'dashboard',
	},
	{
		path: '/PAYC',
		component: PYAC,
		layout: 'dashboard',
	},
	{
		path: '/calculator',
		component: Calculator,
		layout: 'dashboard',
	},
];
export default routes;
