import React from 'react';
import { Button, Container } from 'reactstrap';
import ChevronDown from '../assets/img/chevron-down.png';
import Bounce from 'react-reveal/Bounce';
import CountUp from 'react-countup';
import Pulse from 'react-reveal/Pulse';
import { Fade } from 'react-reveal';
function BuyPayc() {
	return (
		<>
			<Container
				className=' buypayc__container mx-0 px-0 position-relative'
				fluid
			>
				<div className='text-center'>
					<img
						src={ChevronDown}
						alt='down'
						className='img-fluid chevron__down'
					/>
				</div>
				<div className='bg__buy-payc text-center'>
					<Fade left>
						<p className='payc__info-top-tagline mt-5 mb-0'>
							PAYC Finance will deliver a high APY with BUSD
							reflection
						</p>
					</Fade>
					<Pulse>
						<p className='payc__info-heading mb-0'>
							PAYC Finance - The next gen DeFi
						</p>
					</Pulse>
					<p className='payc__info-number mt-5 mb-0'>
						+<CountUp end={385945.8} duration={2.75} decimals={2} />
						%
					</p>
					<Bounce right>
						<p className='main__header-subtitle mt-4'>
							{' '}
							APY + 9% BUSD
						</p>
						<p className='payc__info-desc'>
							Get paid every 15 minutes and grow your $PAYC
							portfolio
						</p>
					</Bounce>
					<div className='d-flex justify-content-center'>
						<p className='mt-4 payc__info-our-contract px-3'>
							<span className='mr-4'>Our Contract :</span>
							<a
								style={{
									color: 'white',
								}}
								href='https://bscscan.com/address/0x9a5a5493b374ac07cfe148b393926edddc55061f'
								target='_blank'
							>
								0x9a5a5493b374ac07cfe148b393926edddc55061f
							</a>
						</p>
					</div>
					<Button className='btn__buy px-4 px-sm-5 py-2 py-sm-3 mt-4'>
						Buy $PAYC{' '}
					</Button>
				</div>
			</Container>
		</>
	);
}

export default BuyPayc;
