import React, { useState } from 'react';
import { Container } from 'reactstrap';
import ActivePlus from '../assets/img/plus-active.png';
import SimplePlus from '../assets/img/plus-simple.png';
import Pulse from 'react-reveal/Pulse';
function Questions() {
	const [active, setActive] = useState(0);
	const toggleFaq = tab => {
		if (tab === active) {
			setActive(0);
		} else {
			setActive(tab);
		}
	};
	return (
		<>
			<Container className='faq__container' fluid>
				<Pulse left>
					<h2 className='section__heading text-center'>
						Still got questions?{' '}
						<br className='d-block d-sm-none' />{' '}
						<span>This Way.</span>
					</h2>
				</Pulse>
				<p className='faq__tagline text-center mt-5'>
					Here you will find answer to most of your questions.
				</p>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 1
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							How to Stake?
						</h2>
						<img
							src={active == 1 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(1)}
						/>
					</div>
					<hr
						className={`${
							active == 1 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 1 ? 'd-block' : 'd-none'
						}`}
					>
						PAYC Finance has created an innovative AutoStaking token
						which provides users a simpler way to receive staking
						rewards. Simply hold $PAYC in your wallet and you will
						automatically receive staking rewards.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 2
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							How do I get my Rebase rewards?
						</h2>
						<img
							src={active == 2 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(2)}
						/>
					</div>
					<hr
						className={`${
							active == 2 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 2 ? 'd-block' : 'd-none'
						}`}
					>
						The Rebase rewards, in simple terms, are the staking
						rewards. You are entitled to receive Rebase Rewards just
						by holding $PAYC in your wallet.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 3
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							How does the Rebase work?
						</h2>
						<img
							src={active == 3 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(3)}
						/>
					</div>
					<hr
						className={`${
							active == 3 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 3 ? 'd-block' : 'd-none'
						}`}
					>
						The Rebase operation allows tokens to work in a way that
						the circulating supply expands or contracts due to
						changes in token price. This increase or decrease in
						supply works with a mechanism called rebasing. PAYC
						Finance uses a Positive Rebase formula which increases
						the $PAYC supply of each holder.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 4
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							When does $PAYC presale start?
						</h2>
						<img
							src={active == 4 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(4)}
						/>
					</div>
					<hr
						className={`${
							active == 4 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 4 ? 'd-block' : 'd-none'
						}`}
					>
						The team behind PAYC will announce soon the release date
						of PAYC Finance. The presale starts this August at the
						latest via Pinksale.finance. Stay tuned and check our
						social media posts
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 5
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							Where can I buy $PAYC tokens?
						</h2>
						<img
							src={active == 5 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(5)}
						/>
					</div>
					<hr
						className={`${
							active == 5 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 5 ? 'd-block' : 'd-none'
						}`}
					>
						The $PAYC token is available on pancakeswap after the
						presale on pinksale.finance.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 6
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							What makes PAYC Finance special and different from
							other DeFi?
						</h2>
						<img
							src={active == 6 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(6)}
						/>
					</div>
					<hr
						className={`${
							active == 6 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 6 ? 'd-block' : 'd-none'
						}`}
					>
						PAYC is a new protocol that attempts to create a new
						decentralized model in DeFi. The PAYC Finance protocol
						places the value the project generates into the wallets
						of PAYC holders rather than building a large treasury.
						This approach provides value immediately for token
						holders and creates an engaged community interested in
						seeing the project continue to thrive. Our goal is to
						leverage the community as one cohesive and focused unit
						to build wealth and power for the community. This is a
						new concept in DeFi and we believe it will set a new
						standard or the industry. In the near future, it will be
						one of the most popular DeFi project in this space.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 7
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							What is the slippage for the trade?
						</h2>
						<img
							src={active == 7 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(7)}
						/>
					</div>
					<hr
						className={`${
							active == 7 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 7 ? 'd-block' : 'd-none'
						}`}
					>
						There are two different Slippages which can be used for
						trading $PAYC: <br />
						- When you wish to buy, you will need to put the
						slippage at least at 11%. <br />
						- When you wish to sell, you will need to put the
						slippage at least at 18%. <br />
						You might need to add higher slippage during times when
						there are lots of buys and sells of PAYC but you should
						only ever incur the same buy and sell fees as mentioned
						above.
					</div>
				</div>
				<div>
					<div className='d-flex align-items-center mt-5'>
						<h2
							className={`${
								active == 8
									? 'faq__heading-active'
									: 'faq__heading'
							}`}
						>
							Is there a Max Supply of $PAYC?
						</h2>
						<img
							src={active == 8 ? ActivePlus : SimplePlus}
							alt='collapse'
							className='img-fluid ml-auto pointer'
							onClick={() => toggleFaq(8)}
						/>
					</div>
					<hr
						className={`${
							active == 8 ? 'active__hr' : 'simple__hr'
						}`}
					/>
					<div
						className={`faq__content ${
							active == 8 ? 'd-block' : 'd-none'
						}`}
					>
						$PAYC is a BEP-20 token which rewards its holders with
						automatic passive interest payments every 15 minutes and
						1 hour for BUSD Reflection over the lifespan of 13.5
						years until the maximum supply of 3.25 Billion token has
						been reached.
					</div>
				</div>
			</Container>
		</>
	);
}

export default Questions;
