import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import DashboardHeader from '../components/DashboardHeader';

function Dashboard() {
	const [activeCard, setActiveCard] = useState(0);
	return (
		<>
			<Container className='pt-5 pb-5'>
				<DashboardHeader title='Dashboard' />
				<Row className='mt-5'>
					{new Array(12).fill(1).map((_, idx) => {
						return (
							<Col
								lg='3'
								md='4'
								sm='6'
								className='mt-3'
								key={idx}
							>
								<div
									onClick={() => setActiveCard(idx)}
									className={` pointer ${
										activeCard == idx
											? 'active__card'
											: 'inactive__card'
									}`}
								>
									<p className='mb-0 card__title'>
										PAYC Price
									</p>
									<p className='mb-0 card__price'>$2.0158</p>
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>
		</>
	);
}

export default Dashboard;
