import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import DashboardHeader from '../components/DashboardHeader';

function ClaimReward() {
	const [activeCard, setActiveCard] = useState(0);
	return (
		<>
			<Container className='pt-5 pb-5'>
				<DashboardHeader title='Claim Reward' />
				<Row className='mt-5 mx-1 claim__row'>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Your Balance</p>
						<p className='reward__price mb-0'>0 PAYCF</p>
						<p className='reward__price mb-0'>0 BUSD</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>APY</p>
						<p className='reward__price mb-0'>385,945.8%</p>
						<p className='reward__subline mb-0'>Daily ROI 2.3%</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Total BUSD Earned</p>
						<p className='reward__price mb-0'>0 BUSD</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Reward Not Claimed</p>
						<p className='reward__price mb-0'>0 BUSD</p>
					</Col>
				</Row>
				<Row className='mt-3'>
					{new Array(8).fill(1).map((_, idx) => {
						return (
							<Col
								lg='3'
								md='4'
								sm='6'
								className='mt-3'
								key={idx}
							>
								<div
									onClick={() => setActiveCard(idx)}
									className={` pointer ${
										activeCard == idx
											? 'active__card'
											: 'inactive__card'
									}`}
								>
									<p className='mb-0 card__title'>
										PAYC Price
									</p>
									<p className='mb-0 card__price'>$2.0158</p>
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>
		</>
	);
}

export default ClaimReward;
