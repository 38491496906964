import React from 'react';
import { Container } from 'reactstrap';
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Q1 from '../assets/img/Q1.png';
import Q2 from '../assets/img/Q2.png';
import Q3 from '../assets/img/Q3.png';
import Q4 from '../assets/img/Q4.png';
import Pulse from 'react-reveal/Pulse';
function Roadmap() {
	return (
		<>
			<Container className=' roadmap__container' fluid>
				<Pulse left>
					<h2 className='section__heading'>ROADMAP</h2>
				</Pulse>
				<div className='mt-5'>
					<VerticalTimeline lineColor='linear-gradient(to bottom, rgba(121, 0, 242, 0.54) 0%, #7900F2 49.27%, rgba(121, 0, 242, 0.54) 100%)'>
						<VerticalTimelineElement
							className='vertical-timeline-element--work q1__work'
							iconStyle={{
								background:
									'linear-gradient(to right, #7900F2 -9.47%, #E000FF 53.8%, #FF3BB7 113.75%)',
								color: '#fff',
							}}
							contentArrowStyle={{
								display: 'none',
							}}
						>
							<div className='roadmap__content'>
								<img src={Q1} alt='Q1' className='img-fluid' />
								<div className='roadmap__child-content'>
									<h2>Q1</h2>
									<p>
										- Website Development <br /> -
										Whitepaper Documentation
										<br />
										- Deployment Phase <br />{' '}
										<span className='ml-2'>
											Smart Contract Deployment - Mainnet
										</span>{' '}
										<br /> - Audit and KYC <br /> - Build
										Phase <br />{' '}
										<span className='ml-2'>
											dApp V1 Dashboard
										</span>
									</p>
								</div>
							</div>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className='vertical-timeline-element--work q2__work'
							iconStyle={{
								background:
									'linear-gradient(to right, #7900F2 -9.47%, #E000FF 53.8%, #FF3BB7 113.75%)',
								color: '#fff',
							}}
							contentArrowStyle={{
								display: 'none',
							}}
						>
							<div className='roadmap__content'>
								<img src={Q2} alt='Q2' className='img-fluid' />
								<div className='roadmap__child-content'>
									<h2>Q2</h2>
									<p>
										- Presale on PinkSale <br /> - PinkLock
										Liquidity Locked 5years <br /> - Twitter
										Marketing Campaign <br /> - YouTube
										Marketing Campaign
									</p>
								</div>
							</div>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className='vertical-timeline-element--work q3__work'
							iconStyle={{
								background:
									'linear-gradient(to right, #7900F2 -9.47%, #E000FF 53.8%, #FF3BB7 113.75%)',
								color: '#fff',
							}}
							contentArrowStyle={{
								display: 'none',
							}}
						>
							<div className='roadmap__content'>
								<img src={Q3} alt='Q3' className='img-fluid' />
								<div className='roadmap__child-content'>
									<h2>Q3</h2>
									<p>
										- CoinGecko Listing <br /> -
										CoinMarketCap Listing
										<br /> - DappRadar Listing <br /> -
										5,000 Token Holders
										<br />- 2nd Generation 1'000 unique 3D
										PAYC NFT
									</p>
								</div>
							</div>
						</VerticalTimelineElement>
						<VerticalTimelineElement
							className='vertical-timeline-element--work q4__work'
							iconStyle={{
								background:
									'linear-gradient(to right, #7900F2 -9.47%, #E000FF 53.8%, #FF3BB7 113.75%)',
								color: '#fff',
							}}
							contentArrowStyle={{
								display: 'none',
							}}
						>
							<div className='roadmap__content'>
								<img src={Q4} alt='Q4' className='img-fluid' />
								<div className='roadmap__child-content'>
									<h2>Q4</h2>
									<p>
										- Build Phase - dApp V2 Dashboard <br />{' '}
										- Deployment Phase <br />{' '}
										<span className='ml-2'>
											dApp V2 Dashboard
										</span>{' '}
										<br /> - Casino / Lottery Game <br /> -
										Cross-Chain Integration <br /> -
										Partnerships
									</p>
								</div>
							</div>
						</VerticalTimelineElement>
					</VerticalTimeline>
				</div>
			</Container>
		</>
	);
}

export default Roadmap;
