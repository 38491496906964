import { useRef } from 'react';
import BuyPayc from '../components/BuyPayc';
import CompetitiveAdvantages from '../components/CompetitiveAdvantages';
import HowItWorks from '../components/HowItWorks';
import MainHeader from '../components/MainHeader';
import Questions from '../components/Questions';
import Roadmap from '../components/Roadmap';
import Team from '../components/Team';
import Tokenomics from '../components/Tokenomics';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AdminView = props => {
	const homeRef = useRef(null);
	const tokenomicRef = useRef(null);
	const teamRef = useRef(null);
	const faqRef = useRef(null);
	const homeScroll = () => homeRef.current.scrollIntoView();
	const tokenomicScroll = () => tokenomicRef.current.scrollIntoView();
	const teamScroll = () => teamRef.current.scrollIntoView();
	const faqScroll = () => faqRef.current.scrollIntoView();
	return (
		<>
			<Header
				homeScroll={homeScroll}
				tokenomicScroll={tokenomicScroll}
				teamScroll={teamScroll}
				faqScroll={faqScroll}
			/>
			<div ref={homeRef}>
				<MainHeader />
			</div>
			<BuyPayc />
			<div ref={tokenomicRef}>
				<Tokenomics />
			</div>
			<Roadmap />
			<CompetitiveAdvantages />
			<HowItWorks />
			<div ref={faqRef}>
				<Questions />
			</div>
			<div ref={teamRef}>
				<Team />
			</div>
			<Footer />
		</>
	);
};

export default AdminView;
