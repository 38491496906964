import React from 'react';
import { Button } from 'reactstrap';

function DashboardHeader({ title }) {
	return (
		<>
			<div className='d-block d-sm-flex align-items-center'>
				<h2 className='dashboard__heading'>{title}</h2>
				<div className='ml-auto text-right'>
					<Button className='connect__wallet '>Connect Wallet</Button>
				</div>
			</div>
		</>
	);
}

export default DashboardHeader;
