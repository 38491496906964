import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import FireIcon from '../assets/img/Fire.png';
import MuseumIcon from '../assets/img/Museum.png';
import MoneyBoxIcon from '../assets/img/Money Box.png';
import RotationIcon from '../assets/img/Update Left Rotation.png';
import Bounce from 'react-reveal/Bounce';
import Pulse from 'react-reveal/Pulse';
function HowItWorks() {
	return (
		<>
			<Container className='how__container' fluid>
				<Row className='justify-content-between align-items-center'>
					<Col xl='4' lg='5'>
						<Bounce left>
							<h2 className='how__work-heading'>
								$PAYC Explained
							</h2>
						</Bounce>
						<p className='how__work-desc mt-3'>
							$PAYC holders are rewarded automatically and will
							receive the interest every 15 minutes just for
							holding $PAYC in their own wallet.
						</p>
					</Col>
					<Col xl='6' lg='7' className='mt-3 mt-lg-0'>
						<Row className='align-items-center'>
							<Col xl='7' lg='7' className='px-0'>
								<Pulse>
									<Card className='fire__card'>
										<CardBody>
											<div className='d-flex align-items-center'>
												<h2 className='mt-4'>
													Auto Fire Pit
												</h2>
												<img
													src={FireIcon}
													alt='fire'
													className='img-fluid ml-auto mt-n3'
												/>
											</div>
											<p className='mt-2'>
												2.5% of all $PAYC traded are
												burnt in the Fire Pit. The more
												that is traded, the more get put
												into the fire causing the fire
												pit to grow in size reducing the
												circulating supply and keeping
												the PAYC Protocol stable.
											</p>
										</CardBody>
									</Card>
								</Pulse>
								<Row className='justify-content-end mt-3'>
									<Col xl='6'>
										<Pulse>
											<Card className='simple__mini-card'>
												<CardBody>
													<div className='d-flex align-items-center'>
														<h2>
															PAYC Treasury
															Protocol
														</h2>
														<img
															src={MuseumIcon}
															alt='fire'
															className='img-fluid ml-auto mt-n5'
														/>
													</div>
													<p>
														The Treasury provides
														support to the PDF in
														the event of an extreme
														price drop in the $PAYC
														token. PAYC Treasure
														also used for massived
														marketing campaigns,
														Cover Insurance
														ANTI-RUG.
													</p>
												</CardBody>
											</Card>
										</Pulse>
									</Col>
								</Row>
							</Col>
							<Col
								xl='4'
								lg='5'
								className='px-0 px-lg-2 mt-3 mt-lg-0'
							>
								<Pulse>
									<Card className='simple__mini-card'>
										<CardBody>
											<div className='d-flex align-items-center'>
												<h2>
													PAYC Dividend Fund (PDF)
												</h2>
												<img
													src={MoneyBoxIcon}
													alt='fire'
													className='img-fluid ml-auto mt-n5'
												/>
											</div>
											<p>
												The PDF serves as an BUSD
												Reflection to achieve most
												profitable, stability and
												longterm sustainability of the
												PAYC Finance Protocol by
												maintaining a consistent
												0.02355% rebase rate paid to all
												$PAYC token holders.
											</p>
										</CardBody>
									</Card>
								</Pulse>
								<Pulse>
									<Card className='simple__mini-card mt-3 '>
										<CardBody>
											<div className='d-flex align-items-center'>
												<h2>Auto-Compounding</h2>
												<img
													src={RotationIcon}
													alt='fire'
													className='img-fluid ml-auto mt-n5'
												/>
											</div>
											<p>
												High Auto-Staking and
												Auto-Compounding Protocol with a
												stunning fixed APY in the DeFi
												space of 385,945.80%. Interest
												rewards are compounded every 15
												minutes for every BSC wallet
												holding any $PAYC tokens.
											</p>
										</CardBody>
									</Card>
								</Pulse>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default HowItWorks;
