import React, { useState } from 'react';
import {
	Button,
	Col,
	Container,
	Input,
	InputGroup,
	InputGroupText,
	Label,
	Row,
} from 'reactstrap';
import DashboardHeader from '../components/DashboardHeader';

function Calculator() {
	return (
		<>
			<Container className='pt-5 pb-5'>
				<DashboardHeader title='Calculator' />
				<Row className='mt-5 mx-1 claim__row justify-content-between'>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>PAYC Price</p>
						<p className='reward__price mb-0'>0</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Current APY</p>
						<p className='reward__price mb-0'>385,945.8%</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Your PAYC Balance</p>
						<p className='reward__price mb-0'>0 PAYC</p>
					</Col>
				</Row>
				<Row className='mt-3 mx-1 calculator__row pt-2 pb-4'>
					<Col lg='4' md='6' className='mt-3'>
						<Label>PAYC Amount</Label>
						<InputGroup>
							<Input value='0' />
							<InputGroupText>
								<span className='input-text'>Use Current</span>
							</InputGroupText>
						</InputGroup>
					</Col>
					<Col lg='4' md='6' className='mt-3'>
						<Label>APY(%)</Label>
						<InputGroup>
							<Input value='0' />
							<InputGroupText>
								<span className='input-text'>Use Current</span>
							</InputGroupText>
						</InputGroup>
					</Col>
					<Col lg='4' md='6' className='mt-3'>
						<Label>No of Day(s)</Label>
						<div className='d-flex'>
							<Input type='range' className='mr-3' />
							<Button size='sm' className='btn__range'>
								365
							</Button>
						</div>
					</Col>
					<Col lg='4' md='6' className='mt-3'>
						<Label>PAYC price at purchase ($)</Label>
						<InputGroup>
							<Input value='0' />
							<InputGroupText>
								<span className='input-text'>Use Current</span>
							</InputGroupText>
						</InputGroup>
					</Col>
					<Col lg='4' md='6' className='mt-3'>
						<Label>Future PAYC market price ($)</Label>
						<InputGroup>
							<Input value='0' />
							<InputGroupText>
								<span className='input-text'>Use Current</span>
							</InputGroupText>
						</InputGroup>
					</Col>
					<Col lg='4' md='6' className='mt-4'>
						<Button block className='mt-4 btn__calculate'>
							Calculate
						</Button>
					</Col>
				</Row>
				<Row className='mt-3 mx-1 py-4 calculator__row'>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>
							Your initial investment
						</p>
						<p className='reward__price mb-0'>$ 0</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Current wealth</p>
						<p className='reward__price mb-0'>0</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>
							PAYC rewards estimation
						</p>
						<p className='reward__price mb-0'>0 PAYC</p>
					</Col>
					<Col lg='3' md='4' sm='6' className='mt-3 text-center'>
						<p className='reward__tagline'>Potential return</p>
						<p className='reward__price mb-0'>0 </p>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Calculator;
